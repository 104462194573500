import { useEffect } from "react";
import "./App.css";

const TARGET_URL = "https://www.capitalone.co.uk/";
const DELAY = 3000;

function App() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace(TARGET_URL);
    }, DELAY);
  });

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo">
          <img
            src="capitalone-logo-primary-white.png"
            alt="Capital One logo primary white"
          />
        </div>
      </header>
    </div>
  );
}

export default App;
